import React, {Fragment, useEffect, useState} from 'react';
import Web3 from 'web3';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import MuiAlert from '@mui/material/Alert';
import banner from '../img/SorryTrumpsBanner.jpeg';
import bannerMobile from '../img/SorryTrumpsBannerMobile.jpeg';
import logo from '../img/SorryTrumpsLogo.png';
import logoMobile from '../img/SorryTrumpsLogoMobile.png';
import{shortenWalletAddress} from '../util/helper';
import{strumpsAbi} from '../util/abi';
import config from '../util/config.json';
import { isBrowser, isMobile } from 'react-device-detect';
import {MobileMintInfo,MobileMetamaskInfo} from './MobileInfo'
import axios from 'axios';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  
const Mint = () => {
    const selectedChainId = config.CHAIN_ID;
    const selectedChainName = config.CHAIN_NAME;

    useEffect(() => {

        if (typeof window.ethereum !== 'undefined') {
            setMetamaskInstalled(true);
            //initialize web3
            window.web3 = new Web3(window.ethereum);
            //enable smart contract revert message handling
            window.web3.eth.handleRevert = true;
                        
            //initalize contract
            window.nftContract = new window.web3.eth.Contract(strumpsAbi, config.CONTRACT_ADDRESS);

            // Add listeners start
            window.ethereum.on("accountsChanged", (accounts) => {
                if (!accounts.length) {//if all accounts disconnects
                    setConnected(false);
                }
                setAccount(accounts[0]);
                getOnChainValues();
            });

            window.ethereum.on('chainChanged', (chainId) => {      
            if(window.web3.utils.hexToNumber(chainId) !== selectedChainId){
                    setInvalidChainId(true);
                }else{
                    setInvalidChainId(false);
                    getOnChainValues();
                }
            });
        }else{
            setMetamaskInstalled(false);
        }
  
    }, [selectedChainId]);


    const [metamaskInstalled, setMetamaskInstalled] = useState(false);
    const [connected, setConnected] = useState(false);
    const [account, setAccount] = useState("");
    const [mintAmount, setMintAmount] = React.useState(1);
    const [invalidChainId, setInvalidChainId] = React.useState(false);
    const [openAlert, setOpenAlert] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState('');
    const [alertSeverity, setAlertSeverity] = React.useState('warning');
    const [loading, setLoading] = React.useState(false);

       

    //on-chain data
    const [tokenPrice, setTokenPrice] = React.useState(0);
    const [maxMintAmount, setMaxMintAmount] = React.useState(0);
    const [contractPaused, setContractPaused] = React.useState(false);
    const [whitelistActive, setWhitelistActive] = React.useState(false);
    const [whitelistAddressMintAmount, setWhitelistAddressMintAmount] = React.useState(0);

    
    const onConnect = async () => {
        const accounts = await window.ethereum.request({
            method: "eth_requestAccounts",
        });
        const chainId = await window.ethereum.request({ method: 'eth_chainId' });

        if(window.web3.utils.hexToNumber(chainId) !== selectedChainId){
            setInvalidChainId(true);
        }
        if(accounts[0]){
            setConnected(true);
            setAccount(accounts[0]);
        }else{
            setAccount(null);
            setConnected(false);
        }
        //retrieve price, pause state etc
        getOnChainValues();
        
    }

    const onMint = async () => {
        checkContractStatus();
        if(contractPaused){
            setOpenAlert(true);
            setAlertMessage('Contract is not open!');
            setAlertSeverity('warning');
            return;
        }

        try {
            setLoading(true);      
            let result;
            if(whitelistActive){
                result = await window.nftContract.methods.mintWhitelist(mintAmount)
                .send({from: window.ethereum.selectedAddress, value: mintAmount*tokenPrice });
            }else{
                result = await window.nftContract.methods.mintPublic(mintAmount)
                .send({from: window.ethereum.selectedAddress, value: mintAmount*tokenPrice });
            }
            
            //const tokenId = result.events.Transfer.returnValues.tokenId;
            if(result.status){                
                setOpenAlert(true);
                setAlertMessage('Your NFTs are minted!');
                setAlertSeverity('info');
                checkWhitelistStatus();
            }
            setLoading(false);
            console.log(result);
            
        } catch (e) {
            setLoading(false);
            console.log(e)
        }
    }

    const changeMintAmount = async (e, type) => {
        if(type === 1){
           if(mintAmount - 1 >= 1){
               setMintAmount(mintAmount - 1);
           }
        }else{
            if(mintAmount < maxMintAmount){
                setMintAmount(mintAmount + 1);
            }
        }
    };
    //retrieve on-chain data from smart contract(price, paused, maxMintAmount)
    const getOnChainValues = async() => {
        //retrieve public sale token price
        const pricePerToken = await window.nftContract.methods.pricePerToken().call({from: window.ethereum.selectedAddress });
        setTokenPrice(pricePerToken);
        //checkContractStatus(paused: true/false)
        checkContractStatus();
        //check whitelist status
        checkWhitelistStatus();
        
        //retieve maxMintAmount     
        const mintAmount = await window.nftContract.methods.maxMintAmount().call({from: window.ethereum.selectedAddress });
        setMaxMintAmount(mintAmount);
    }

    const checkContractStatus = async() =>{
        //retieve contract pause status        
        const isPaused = await window.nftContract.methods.paused().call({from: window.ethereum.selectedAddress });
        setContractPaused(isPaused);
    }
    const checkWhitelistStatus = async() =>{
        //retieve whitelist status        
        const isWhitelistActive = await window.nftContract.methods.whitelistAllowed().call({from: window.ethereum.selectedAddress });        
        setWhitelistActive(isWhitelistActive);
        const wlAddressMintAmount = await window.nftContract.methods.getWhitelistAddress(window.ethereum.selectedAddress).call({from: window.ethereum.selectedAddress });
        setWhitelistAddressMintAmount(wlAddressMintAmount);
    }





    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenAlert(false);
    };
    


    const connectView = (
        <Fragment>            
            <p>Connect your wallet and mint some awesome NFTs!</p>

            <Button name="onboardButton" variant="contained"  size="large" onClick={e => onConnect(e)}>Connect</Button>
        </Fragment>
      );

      const mintView = (
        <Fragment>
            <Typography sx={{ fontSize: 18 }} color="#fce4ec" gutterBottom>
                <br/>Connected: {shortenWalletAddress(account)}
                <br/>1 NFT costs ${tokenPrice ? window.web3.utils.fromWei(tokenPrice, 'ether'):""} ETH. (gas fees excluded.)
                <br/>{whitelistActive ?  <Alert severity="warning">Whitelist is active! You have {whitelistAddressMintAmount} more mints.</Alert> : ''}
            </Typography>

            
            { invalidChainId ? <Alert severity="warning">Please connect to {selectedChainName} network</Alert> : ""}
            {!loading ?
            <>
            <Stack direction="column" spacing={2}>                
                <Box sx={{ '& > :not(style)': { m: 1 } }}>
                    <Fab size="small" color="primary" aria-label="add" onClick={e => changeMintAmount(e, 1)} >
                        <RemoveIcon />
                    </Fab>
                    {mintAmount}                        
        
                    <Fab size="small" color="primary" aria-label="add" onClick={e => changeMintAmount(e, 2)} >
                        <AddIcon />
                    </Fab>                  
                    <br/>
                    <Button name="mintButton" variant="contained"  size="large" onClick={e => onMint(e)} disabled={invalidChainId || (whitelistActive && (whitelistAddressMintAmount<=0 || mintAmount>whitelistAddressMintAmount)) }>Mint</Button>
                </Box>                
                
            </Stack> 
            </>
            :
            <CircularProgress value={20}/> 

            }
        </Fragment>
      );

      const installMetamaskView = (
        <Fragment>
            <br/>
             <Alert severity="warning">
                 {isMobile ? "Please open this window in your MetaMask browser to mint NFTs." : "Please install Metamask browser extension to mint NFTs."}                
            </Alert> 
            {isMobile ?MobileMetamaskInfo:""}
        </Fragment>
      );
      
    return <Fragment>
        <div className="App">
        <header className="App-header">
            <img src={isMobile ? logoMobile: logo} alt="banner" />        
            <img src={isMobile ? bannerMobile : banner}  alt="banner" />
            {metamaskInstalled ? (connected ? mintView : connectView) : installMetamaskView}
            
            <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleAlertClose}>
                <Alert onClose={handleAlertClose} severity={alertSeverity} sx={{ width: '100%' }}>
                    {alertMessage}
                </Alert>
            </Snackbar>
        </header>
        </div>
      </Fragment>;
    
}

export default Mint;