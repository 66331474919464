
import './App.css';

import Mint from './components/Mint';

function App() {
  return (
    <Mint/>
  );
}

export default App;
